import Logo from "./../img/logo.png";
import HamburgerIcon from "./../img/hamburger-menu.svg";
import React from "react";
import styles from "../styles/components/Header.module.css";

export default function Header({ hamburgerClick }) {
  return (
    <div className={styles.main_header}>
      <div className="container">
        <div className={styles.header_inner}>
          <div className={styles.brand}>
            <img src={Logo} alt="logo" />
          </div>
          <div
            className={styles.hamburger_menu}
            onClick={() => hamburgerClick(true)}
          >
            <img src={HamburgerIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
