import React, { useState } from "react";
import ChevronIcon from "./../img/chevron-up.svg";
import styles from "../styles/components/BackToTopButton.module.css";

function BackToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };

  // Function to handle scrolling and toggle button visibility
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener when component mounts
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={`${styles.back_to_top_button} ${
        isVisible ? styles.visible : ""
      }`}
      onClick={scrollToTop}
    >
      <img src={ChevronIcon} alt="Back to top" />
    </button>
  );
}

export default BackToTopButton;
