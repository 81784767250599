import React from "react";
import styles from "../styles/components/Footer.module.css";

export default function Footer({ linkData, changeActiveTab, activeTab }) {
  return (
    <div className={`${styles.footer}`}>
      <div className={styles.footer_content}>
        {/* Category Links */}
        <ul className={styles.footer_links}>
          {linkData?.map((link, index) => (
            <li
              className={`${styles.footer_link} ${
                activeTab == index && styles.active
              }`}
              key={"link" + index}
              onClick={() => {
                changeActiveTab(index);
              }}
            >
              {link.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
