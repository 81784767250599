import "./App.css";
import React, { useEffect, useState } from "react";
import { request } from "graphql-request";
import ProjectItem from "./components/projectItem";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import BackToTopButton from "./components/BackToTopButton";

function App() {
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [copiedText, setCopiedText] = useState("Copy Link");
  const [currentTab, setCurrentTab] = useState("Qode Corp");
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  useEffect(() => {
    const query = `
    {
     titles {
       title
       records {
         image {
           url
           width
           height
         }
         link
         name
         position
         filter {
           title
         }
       }
     }
   }
  `;
    request(
      "https://api-ap-south-1.graphcms.com/v2/cl1ubmcxxammk01xof9uh6gue/master",
      query
    ).then((result) => {
      setData(result.titles);
    });
  }, []);

  // Function to open sidebar
  const openSidebar = (val) => {
    setSidebarIsOpen(val);
  };

  // Get the title of active tab
  const activeTitle = data && data[activeTab] ? data[activeTab].title : "";

  return (
    <div className="main-wrapper">
      {/* Header */}
      <Header hamburgerClick={openSidebar} />
      {/* Sidebar */}
      <Sidebar
        linkData={data}
        isSidebarOpen={sidebarIsOpen}
        changeSidebarState={setSidebarIsOpen}
        changeActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <div className="container">
        <div className="active_tab_title">
          <h1 className="title">{activeTitle}</h1>
        </div>
        <div className="tab">
          {data &&
            data.map((currElem, id) => (
              <div
                className={
                  id === activeTab ? "categories active" : "categories"
                }
                id={`tab_${id}`}
                key={id}
              >
                <div className="logo-section">
                  {currElem.records
                    .sort((a, b) => b.position - a.position)
                    .map((project, id) => (
                      <ProjectItem
                        key={id}
                        cat={currElem.title}
                        project={project}
                        index={id}
                        copiedText={copiedText === id ? "Copied" : "Copy Link"}
                        setCopiedText={setCopiedText}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                      />
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* Footer */}
      <Footer
        linkData={data}
        changeActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <BackToTopButton />
    </div>
  );
}

export default App;
